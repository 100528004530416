import React, {Component} from 'react';
import {IntlProvider} from 'react-intl';
import Header from './components/sections/header'
import TopBanner from './components/sections/top-banner';
import ForgetTiredness from './components/sections/forget-tiredness';
import VitaminFacts from './components/sections/vitamin-facts';
import VitaminSources from './components/sections/vitamin-sources';
import VitaminNeed from './components/sections/vitamin-need';
import VitaminDeficiency from './components/sections/vitamin-deficiency';
import IncreasedNeed from './components/sections/increased-need';
import TopBuyNow from './components/sections/top-buy-now';
import VitaminIron from './components/sections/vitamin-iron';
import VitaminWho from './components/sections/vitamin-who';
import MiddleBuyNow from './components/sections/middle-buy-now';
import VitaminZinc from './components/sections/vitamin-zinc';
import VitaminWhoRe from './components/sections/vitamin-who-re';
import BottomBuyNow from './components/sections/bottom-buy-now';
import Specialists from './components/sections/specialists';
import Share from './components/sections/share';
import Footer from './components/sections/footer';
import messages from "./locales/messages";

class Page extends Component {
    render() {
        const { locale } = this.props;
        return (
            <IntlProvider onError={() => {}} locale={locale} messages={messages[locale]}>
                <div className="page">
                    <Header locale={locale} />
                    <TopBanner locale={locale}/>
                    <ForgetTiredness/>
                    <VitaminFacts/>
                    <VitaminSources locale={locale}/>
                    <VitaminNeed/>
                    <VitaminDeficiency/>
                    <IncreasedNeed/>
                    <TopBuyNow />
                    <VitaminIron locale={locale}/>
                    <VitaminWho/>
                    <MiddleBuyNow/>
                    <VitaminZinc/>
                    <VitaminWhoRe/>
                    <BottomBuyNow/>
                    {/* <Specialists/> */}
                    <Share/>
                    <Footer locale={locale}/>
                </div>
            </IntlProvider>
        );
    }
}

export default Page;

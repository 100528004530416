import React from 'react';
import './index.css';
import {FormattedMessage, injectIntl} from "react-intl";

const Video = ({url}) => (
    <iframe
        width="247"
        height="166"
        src={url}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
    >
    </iframe>
);

export default injectIntl(({intl}) => (
    <div id="specialists" className="specialists">
        <div 
            className="specialists__header"
            dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'specialists.header'})}}
        />
        <div className="specialists__videos">
            <Video url="https://www.youtube.com/embed/dQw4w9WgXcQ"/>
            <Video url="https://www.youtube.com/embed/dQw4w9WgXcQ"/>
            <Video url="https://www.youtube.com/embed/dQw4w9WgXcQ"/>
            <Video url="https://www.youtube.com/embed/dQw4w9WgXcQ"/>
        </div>
    </div>
));
